

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../config";
import { Broker } from "../../models/super-admin/broker.model";
import Axios from "axios";
import { authHeader } from "../../services/auth";
//import UpdateEmailAndPhone from "../../../common-components/UpdateEmailAndPhone.vue";
import CommonDeleteModal from "../../common-components/CommonDeleteModal.vue";
import CommonUserChoiceModal from "../../common-components/CommonUserChoiceModal.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
// import UpdateEmailAndPhone from "../../common-components/UpdateEmailAndPhone.vue";

@Component({
  components: {
    CommonDeleteModal,
    PerfectScrollbar,
    CommonUserChoiceModal,
    // UpdateEmailAndPhone,
  },
})
export default class ManageBroker extends Vue {
  /* eslint-disable */
  public BrokerList = [];
  public isEdit = false;
  public userInfo: any = new Broker();
  public emailExist = false;
  public selectBroker = {};
  public msg = "";
  public totalLength = 0;
  public noChange = false;
  public currentLengthOnScroll = 0;
  public skip = 0;
  public fullName: any = "";
  public isReset = false;
  public showLoader = false;
  public BrokerCompanyList = [];
  public search = "";
  public SpacificBrokerlist = [];
  public agentId: any = null;
  public brokerId = "0";
  public phoneExist = false;
  //public companyPhoneError = false
  public isUpdate = false;
  public stateAndCounty = [];
  public sortType = null;
  public sortParam = null;

  public async getSpacificBrokerlist() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}common/broker/getSpacificBrokerlist`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.SpacificBrokerlist = response.data;
        // console.log(this.SpacificBrokerlist,'this.SpacificBrokerlist');
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getBrokerList(mountCall) {
    mountCall
      ? (this.$store.state.tmpcoLoader = true)
      : (this.showLoader = true);
    try {
      this.skip = mountCall ? 0 : this.skip + 15;

      let path = "";
      path = "common/broker/getBrokerList";
      const response = await Axios.get(BASE_API_URL + path, {
        params: {
          fullName: this.isReset ? "" : this.fullName,
          // companyName:  this.companyName,
          brokerId: this.brokerId,
          options: {
            skip: this.skip,
            limit: 15,
          },
          sortType: this.sortType,
          sortParam: this.sortParam,
        },
        
        headers: authHeader(),
      });
      if (response.status === 201) {
        this.isReset = false;
        if (mountCall) {
          this.BrokerList = response.data.brokerList ? response.data.brokerList : [];
        } else {
          const data = response.data.brokerList ? response.data.brokerList : [];
          this.BrokerList = this.BrokerList.concat(data);
        }
        this.totalLength = response.data.brokerdataLength;
        
        this.currentLengthOnScroll = this.BrokerList
          ? this.BrokerList.length
          : 0;
        //  this.fullName = "" 
        // this.$emit("total-loan", this.totalLength);
      }
    } catch (error) {
      this.isReset = false;
      console.log(error);
    }
    mountCall
      ? (this.$store.state.tmpcoLoader = false)
      : (this.showLoader = false);
  }
  // public async activeDeactiveUser(userId, isUserActive) {
  //   try {
  //     const response = await Axios.post(
  //       BASE_API_URL + "super-admin/broker/activeDeactiveUser",
  //       { userId: userId, isUserActive: isUserActive },
  //       { headers: authHeader() }
  //     );
  //     if (response.data.status == 200) {
  //       this.$snotify.success(response.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  public async getBrokerCompanyList() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}common/broker/getBrokerCompanyList`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.BrokerCompanyList = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async getSpacificBrokerData(agentId) {
    this.isUpdate = false
    if (this.agentId != "0" && this.agentId != "new") {
      this.BrokerCompanyList.forEach((e: any) => {
        if (e._id == agentId)
          this.userInfo = { ...this.userInfo, ...e };
          //this.agentId = e._id;
        // this.userInfo.fullName = {
        //   firstName: null,
        //   lastName: null,
        // };
      });
      console.log(agentId, "agentId");
    } else {
      this.userInfo = new Broker();
      
    }
  }

  public async activeDeactiveUser(data) {
    try {
      const selectedUser = data;
      const status = selectedUser.isUserActive ? "Active" : "De Activate";
      await this.$refs.refrence["confirmationBox"]({
        msg: `Are you sure want to ${status} the ${selectedUser.fullName.firstName} ${selectedUser.fullName.lastName} Broker ?`,
        data: {
          data: data,
        },
        apiPath: "common/broker/activeDeactiveUser",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async resendRegisterToken(data) {
    try {
      const res = await Axios.post(
        BASE_API_URL + "common/resendRegisterToken",
        {
          userId: data.brokerUserId,
          fullName: data.fullName,
          userType : 2
        }
      );
      if (res.status == 200) {
        this.$snotify.success(res.data.message);
      } else if (res.status == 400) this.$snotify.error(res.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  public async resendLinkToBroker(data) {
    try {
      
      const LoanOfficerData = {
        email: data.email,
        userId: data.brokerUserId,
        fullName: data.fullName,
        userType : 2,
        isVerification: data.isVerification
      };
      const response = await Axios.post(
        `${BASE_API_URL}common/broker/resendLinkToBroker`,
        LoanOfficerData,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.$snotify.success(
          "Verification link has been re-sent to your email."
        );
      }
    } catch (error) {
      console.log(error);
    }
  }


public trimEmail() {
    this.userInfo.email = this.userInfo.email.trim();
  }


  public async saveBroker() {
    this.$store.state.tmpcoLoader = true;

    try {
      const response = await Axios.post(
        BASE_API_URL + "common/broker/saveBroker",
        {
          userInfo: this.userInfo,
          isEdit: this.isEdit,
        },
        { headers: authHeader() }
      );
      if (response.data.status == 200) {
        this.$snotify.success(
          "You have been registered successfully. Verification mail has been sent to you email."
        );
        this.referesh();
        this.cancel();
        await this.getBrokerList(true);
        await this.getSpacificBrokerData(this.agentId);
        // this.$modal.hide("addBroker");
      } else if (response.data.status == 201) {
        this.$snotify.success("Broker details Updated successfully.");
        this.$modal.hide("addBroker");
        await this.getBrokerList(true);

        //this.$modal.hide("addBroker");
      } else if (response.data.status == 400) {
        this.emailExist = response.data.emailExist;
        this.$snotify.error("Email Address already Exist.");
      } else {
        this.$snotify.error("Something went wrong.");
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async deleteBroker(selectedUser) {
    try {
      
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this ${selectedUser.fullName.firstName} ${selectedUser.fullName.lastName} Broker ?`,
        data: { id: selectedUser.brokerUserId },
        apiPath: "common/broker/deleteBroker",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async updateEmail() {
    try {
      if (this.noChange) {
        this.$snotify.info("No Changes");
        this.$modal.hide("changeEmail");
        this.noChange = false;
        return;
      }
      const response = await Axios.post(
        BASE_API_URL + "common/broker/updateEmail",
        this.selectBroker,
        { headers: authHeader() }
      );
      if (response.data.status == 201) {
        this.$snotify.success("Email Address updated Successfully.");
        await this.getBrokerList(true);
        this.$modal.hide("changeEmail");
        //this.$modal.show("confirmation");
      } else if (response.data.status == 400) {
        this.emailExist = response.data.emailExist;
        this.$snotify.error("Email Address already Exist.");
      } else if (response.status === 202) {
        this.msg = response.data.message;
      } else {
        this.$snotify.error("Something went wrong.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // public async updateEmailAndPhone(selectedUser) {
  //   try {
  //     const data = { phone: selectedUser.companyPhone };

  //     await this.$refs.REF["updateData"]({
  //       ...data,
  //       // userId: selectedUser.userId,
  //       userId: selectedUser._id,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public getCompanyName(companyName) {
    this.BrokerCompanyList.map((e: any) => {
      if (e.companyName === companyName) {
        this.agentId = e._id;
      }
    });

    
 
  }

  public showChangeEmailModal(data) {
    this.selectBroker = JSON.parse(JSON.stringify(data));
    console.log(this.selectBroker);
    this.$modal.show("changeEmail");
  }

  public removeValidation() {
    this.emailExist = false;
  }

  public cancel() {
    this.$modal.hide("addBroker");
    //this.userInfo = null;
    this.userInfo = new Broker();
    this.agentId = null;

    // this.assigneeList = [];
    //this.processorData = new Processor();
    //this.$bvModal.hide("assignRole");
  }

  public addBroker() {
    this.$modal.show("addBroker");
    this.isEdit = false;
    this.userInfo = new Broker();
    this.agentId = null;
    this.isUpdate = false;
  }

  public async scrollHandle(evt) {
    let scroll: any = this.$refs.scrollbar;
    if (
      !this.showLoader &&
      scroll.ps.reach.y == "end" &&
      // x.scrollTop + x.clientHeight <= x.scrollHeight &&
      this.currentLengthOnScroll < this.totalLength
    ) {
      await this.getBrokerList(false);
    }
  }
  public async getStatesAndCounty() {
      try {
        const response = await Axios.get(
          BASE_API_URL + "common/getStatesAndCounty"
          );
        this.stateAndCounty = response.data; 
         
      } catch (error) {
        console.log(error);
      }
    }

  public resetList() {
    try {
      this.brokerId = "0";
      this.fullName = "";
      this.getBrokerList(true);
    } catch (error) {
      console.log(error);
    }
  }

    public  async referesh()
    {await this.getBrokerList(true);
    await this.getBrokerCompanyList();
    await this.getSpacificBrokerlist();
    await this.getStatesAndCounty();
   // this.$modal.show('addBroker');

    }

  async mounted() {
    await this.getBrokerList(true);
    await this.getBrokerCompanyList();
    await this.getSpacificBrokerlist();
    await this.getStatesAndCounty();
  }

  /* eslint-disable */
}
