import fullName from '../common/name.model'
import Address from '../common/address.model'

export class Broker {
    constructor() {
        this.userId = null;
        this.email = null;
        this.companyPhone = null;
        this.logo = null;
        // this.companyNMLS = Number;
        // this.individualNMLS = Number;
        this.address = new Address();
        this.fullName = new fullName();
    }

    public userId: any;

    // public companyNMLS: any;

    public email: any;

    public companyPhone: any;

    public logo: any;

    // public individualNMLS: any;

    public address: Address;

    public fullName: fullName;


}
